import "./footer.css";
// import {
//   AiFillFacebook,
//   AiFillInstagram,
//   AiOutlineTwitter,
// } from "react-icons/ai";

const Footer = () => {
  return (
    <footer>
      <div className="s-m d-flex al-start md-f-column jc-between">
        <div className="logo-footer">
          <img
            style={{ maxWidth: "180px" }}
            src={process.env.REACT_APP_PUBLIC_FOLDER + "/assets/logo.png"}
            alt=""
          />
        </div>
        {/* <div>
          <p className="f-title">ที่อยู่</p>
          <span>
            ต.ในเมือง <br />
            อ.เมือง <br />
            จ.พิษณุโลก 65000
          </span>
        </div> */}
        {/* <div>
          <p className="f-title">เมนู</p>
          <ul className="al-center jc-around">
            <li>
              <NavLink className="list" to="/">
                หน้าแรก
              </NavLink>
            </li>
            <li>
              <NavLink className="list" to="/products">
                สินค้า
              </NavLink>
            </li>
            <li>
              <NavLink className="list" to="/how-to-order">
                วิธีการสั่งซื้อ
              </NavLink>
            </li>
            <li>
              <NavLink className="list" to="/payment">
                การชำะเงิน
              </NavLink>
            </li>
            <li>
              <NavLink className="list" to="/contact-us">
                ติดต่อเรา
              </NavLink>
            </li>
          </ul>
        </div> */}
        <div>
          <p className="f-title">ช่องทางการติดต่อ</p>
          <a href="#">Line OA: @Code-Lab.dev</a>
          {/* <div className="d-flex al-center jc-start gap-small social-footer">
            <AiFillFacebook />
            <AiOutlineTwitter />
            <AiFillInstagram />
          </div> */}
        </div>
      </div>
      <p className="cpr">Copyright © 2023 Code-Lab.dev	All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
