import "./categories.css";
import { AiOutlinePlus } from "react-icons/ai";
import { BsThreeDots, BsCheck } from "react-icons/bs";
// import { RiArrowDropDownLine } from "react-icons/ri";
import { useEffect, useRef, useState } from "react";
// import Pagination from "../../components/pagination/Pagination";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth.service";

const Categories = () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  // check role
  useEffect(()=>{
    if(currentUser?.role === 3 ){
      navigate("/admin/orders");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentUser?.role])

  // const [popupCate, setPopupCate] = useState(false);
  // const [textOptionCate, setTextOptionCate] = useState("เลือกหมวดหมู่");

  const inputName = useRef(null);

  const [newCate, setNewCate] = useState(true);
  const [updateCateData, setUpdateCateData] = useState({});

  const handleClickActionTable = (e) => {
    const parent = e.target.closest(".actions");
    const dropdown = parent.querySelector(".dropdown");

    dropdown.style.display === "none" || dropdown.style.display === ""
      ? (dropdown.style.display = "block")
      : (dropdown.style.display = "none");
  };

  const [values, setValues] = useState({
    name: "",
    parentCate: 0,
  });

  const handleChange = (e) => {
    setValues((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleClickSave = async (status) => {
    try {
      if (values.name === "") return;
      await axios.post("/category", {
        name: values.name,
        status: status,
        parentCate: values.parentCate,
      });

      const fetchCate = async () => {
        const res = await axios.get("/category/all");
        setCategory(res.data);
      };
      fetchCate();

      inputName.current.value = "";
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  const handleClickUpdate = async (status) => {
    try {
      if (values.name === "") return;
      await axios.put(`/category/${updateCateData.id}`, {
        name: values.name,
        status: status,
        parentCate: values.parentCate,
      });

      const fetchCate = async () => {
        try {
          const res = await axios.get("/category/all");
          setCategory(res.data);
        } catch (err) {
          console.log(err);
        }
      };
      fetchCate();

      inputName.current.value = "";
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  const handleDel = async (id) => {
    try {
      await axios.delete("/category/" + id);
      setCategory(
        category.filter((cate) => {
          return cate !== category.find((c) => c.id === id);
        })
      );
    } catch (err) {
      alert("เกิดข้อผิดพลาด");
      console.log(err);
    }
  };

  // CATEGORY
  const [category, setCategory] = useState([]);
  useEffect(() => {
    const fetchCate = async () => {
      try {
        const res = await axios.get("/category/all");
        setCategory(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCate();
  }, []);

  return (
    <div className="categories">
      <div className="d-flex jc-between al-center margin-bt-1">
        <h2 className="heading">หมวดหมู่สินค้า</h2>
        <button
          className="primary-button"
          onClick={() => {
            setNewCate(true);
          }}
        >
          <AiOutlinePlus /> เพิ่มหมวดหมู่
        </button>
      </div>

      <div className="d-flex gap-25px md-f-rev-column">
        {/* table */}
        <div className="table mt-0">
          <p className="title">หมวดหมูสินค้า ({category.length})</p>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>ชื่อหมวดหมู่</th>
                {/* <th>หมวดหมู่หลัก</th> */}
                <th>สถานะ</th>
                <th>คำสั่ง</th>
              </tr>
            </thead>
            <tbody>
              {category.length > 0 ? (
                category.map((c) => (
                  <tr key={c.id}>
                    <td>{c?.id}</td>
                    <td>{c?.name}</td>
                    {/* <td>
                      {c?.parentCate.length > 0
                        ? c?.parentCate[0].parentName
                        : "-"}
                    </td> */}
                    <td>
                      {c?.status === 1 ? (
                        <span className="status-complete">เผยแพร่</span>
                      ) : (
                        <span className="status-pending">แบบร่าง</span>
                      )}
                    </td>
                    <td>
                      <div className="actions">
                        <BsThreeDots
                          onClick={(e) => {
                            handleClickActionTable(e);
                          }}
                        />
                        <div className="dropdown">
                          <ul
                            onClick={(e) => {
                              handleClickActionTable(e);
                            }}
                          >
                            <li
                              className="link"
                              onClick={() => {
                                setNewCate(false);
                                setUpdateCateData(c);
                                inputName.current.value = c.name;
                                // c.parentCate?.length > 0
                                //   ? setTextOptionCate(
                                //       c.parentCate[0]?.parentName
                                //     )
                                //   : setTextOptionCate("เลือกหมวดหมู่");
                                setValues((prev) => ({
                                  ...prev,
                                  name: c.name,
                                }));
                              }}
                            >
                              แก้ไข
                            </li>
                            <li
                              className="link red-color"
                              onClick={() => {
                                handleDel(c.id);
                              }}
                            >
                              ลบ
                            </li>
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* form */}
        <div className="box-content cate-form">
          <p className="title">{newCate ? "เพิ่มหมวดหมู่" : "แก้ไขหมวดหมู่"}</p>

          <div className="container-input">
            <p className="title">
              ชื่อหมวดหมู่สินค้า<span>*</span>
            </p>
            <input
              type="text"
              ref={inputName}
              placeholder="กรอกชื่อสินค้า"
              defaultValue={newCate === false ? updateCateData.name : ""}
              name="name"
              onChange={handleChange}
            />
            {/* <p className="title">หมวดหมู่สินค้า</p>
            <div
              className="d-flex al-center jc-end"
              style={{ position: "relative" }}
            >
              <div
                className="d-flex al-center jc-between option ml-0"
                onClick={() => {
                  setPopupCate(true);
                }}
              >
                {textOptionCate} <RiArrowDropDownLine />
              </div>
              <div
                className="dropdown"
                style={{ display: popupCate ? "block" : "none", width: "100%" }}
                onClick={(e) => {
                  setPopupCate(false);
                }}
              >
                <ul
                  onClick={(e) => {
                    setTextOptionCate(e.target.innerText);
                    setValues((prev) => ({
                      ...prev,
                      parentCate: e.target.value,
                    }));
                  }}
                  // defaultChecked={newCate === false && updateCateData.parentCate?.length > 0 ? updateCateData.parentCate[0]?.parentId : 0}
                >
                  {newCate === false &&
                  updateCateData.parentCate?.length > 0 ? (
                    <li
                      value={updateCateData.parentCate?.parentId}
                      className={
                        textOptionCate ===
                        `${updateCateData.parentCate[0]?.parentName}`
                          ? "link active"
                          : "link"
                      }
                    >
                      {updateCateData.parentCate[0]?.parentName}
                    </li>
                  ) : (
                    <li
                      value={0}
                      className={
                        textOptionCate === "เลือกหมวดหมู่"
                          ? "link active"
                          : "link"
                      }
                    >
                      เลือกหมวดหมู่
                    </li>
                  )}

                  {category.length > 0
                    ? category.map((c) => {
                        if (c.parentCate.length === 0) {
                          return (
                            <li
                              key={c.id}
                              value={c.id}
                              className={
                                textOptionCate === c?.name
                                  ? "link active"
                                  : "link"
                              }
                            >
                              {c?.name}
                            </li>
                          );
                        } else {
                          return "";
                        }
                      })
                    : ""}
                </ul>
              </div>
            </div> */}
            <div className="d-flex al-center jc-between gap-small mt-2">
              <button
                className="primary-button"
                onClick={() => {
                  if (newCate === true) {
                    handleClickSave(1);
                  } else {
                    handleClickUpdate(1);
                  }
                }}
              >
                <BsCheck /> เผยแพร่
              </button>
              <button
                className="second-button"
                onClick={() => {
                  if (newCate === true) {
                    handleClickSave(0);
                  } else {
                    handleClickUpdate(0);
                  }
                }}
              >
                {newCate ? "บันทึกเป็นแบบร่าง" : "บันทึกเป็นแบบร่าง"}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <Pagination /> */}
    </div>
  );
};

export default Categories;
