import './review.css'
// import { FaQuoteLeft } from 'react-icons/fa'
import { useEffect, useRef, useState } from 'react'
// import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
// import { Carousel } from 'antd'
import axios from 'axios'

const Review = () => {
    // eslint-disable-next-line no-unused-vars
    const [maxSizeWidth, setmaxSizeWidth] = useState(0)
    const resSize = useRef(null)
    useEffect(() => {
        setmaxSizeWidth(resSize.current.offsetWidth)
    }, [resSize?.current?.offsetWidth])

    const [data, setData] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [dataFull, setDataFull] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get('/review')
                setData(res.data)
                const full = res.data.filter(d => (d.full === 1))
                setDataFull(full)
            } catch (err) {
                console.log(err)
            }
        }
        fetchData();
    }, [])

    return (
        <div className='review'>
            <p className="u-title">รีวิวจากลูกค้า</p>
            <div className="d-grid gap-25px mt-2 g-3 md-g-1" ref={resSize}>
                {data.length > 0 ? (
                    data.map(d => (
                        <div className="box" key={d.id} style={{padding: '0', border: 'none'}}>
                             <img src={process.env.REACT_APP_UPLOAD_URL + `/${d?.img}`} alt="" style={{width: '100%', height: '100%', objectFit: 'cover'}} />
                        </div>
                    ))
                    
                ) : ''}
                {/* {data.length > 0 ? (
                    data.map(d => (
                        d.full === 0 ? (
                            <div className="box" key={d.id}>
                                <div className="border-round d-flex al-center jc-center avatar">
                                    <img src={process.env.REACT_APP_UPLOAD_URL + `/${d?.img}`} alt="" />
                                </div>
                                <p className="name"><b>{d?.name}</b></p>
                                <p className="job">{d?.name ?? '-'}</p>
                                <FaQuoteLeft />
                                <p className="text">{d?.detail}</p>
                            </div>
                        ) : ''
                    ))
                ) : ''} */}
            </div>

            {/* long review */}
            {/* {dataFull.length > 0 ? (
                <>
                    <Carousel ref={slider} dots={false} style={{ maxWidth: `${maxSizeWidth - 1}px` }}>
                        {data.map(d => (
                            <div className='mt-2'>
                                <div className="d-flex jc-start md-f-column l-review w-100">
                                    <div className="d-flex al-center jc-center image">
                                        <img src={process.env.REACT_APP_UPLOAD_URL + `/${d?.img}`} alt="" />
                                    </div>
                                    <div className="d-flex al-start f-column jc-center detail w-100">
                                        <FaQuoteLeft />
                                        <p className='text'>{d?.detail}</p>
                                        <p className="name"><b>{d?.name}</b></p>
                                        <p className="job">{d?.job ?? '-'}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Carousel>

                    <div className="s-m d-flex al-center jc-end gap-small banner-controller">
                        <button className='d-flex al-center jc-center prev-btn' onClick={() => { slider.current.prev() }}><FaAngleLeft /></button>
                        <button className='d-flex al-center jc-center next-btn' onClick={() => { slider.current.next() }}><FaAngleRight /></button>
                    </div>
                </>
            ) : ''} */}
        </div>
    )
}

export default Review