import "./miniCart.css";
import { AiOutlineShoppingCart } from "react-icons/ai";
import SidebarCart from "../sidebarCart/SidebarCart";
import { useEffect, useState } from "react";

// redux
import { useSelector } from "react-redux";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const MiniCart = () => {
  const products = useSelector((state) => state.cart.products);

  const [showSidebar, setShowSidebar] = useState(false);

  const [total, setTotla] = useState(0);
  const [totalItems, setTotlaItems] = useState(0);
  useEffect(() => {
    const getTotal = () => {
      let total = 0;
      let amoutItems = 0;
      products &&
        products.forEach((p) => {
          total += p.price * p.quantity;
          amoutItems += p.quantity;
        });
      setTotla(formatNumber(total));
      setTotlaItems(amoutItems);
    };
    getTotal();
  }, [products]);

  useEffect(() => {
    showSidebar ? document.body.style.overflow = "hidden" : document.body.style.overflow = ""
  }, [showSidebar])

  return (
    <>
      <div
        className="d-flex al-center jc-center gap-small mini-cart"
        onClick={() => {
          setShowSidebar(true);
        }}
      >
        <div
          className="d-flex al-center jc-center"
          style={{ position: "relative" }}
        >
          <AiOutlineShoppingCart />
          <span className="count d-flex al-center jc-center border-round">
            {totalItems}
          </span>
        </div>
        <span className="total">฿{total}</span>
      </div>
     {showSidebar &&  <SidebarCart show={showSidebar} setShowSidebar={setShowSidebar} />}
      {showSidebar && (
        <div
          className="u-overlay show"
          onClick={() => {
            setShowSidebar(false);
          }}
        ></div>
      )}
    </>
  );
};

export default MiniCart;
