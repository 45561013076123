import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineCalendar } from "react-icons/ai";
import { DatePicker, Space } from "antd";
import "dayjs/locale/th";
import locale from "antd/es/date-picker/locale/th_TH";
import { useState } from "react";

const { RangePicker } = DatePicker;

const FilterChart = ({ timeStart, timeEnd, payment }) => {
  const [popupStatus, setPopupStatus] = useState(false);
  const [textOptionStatus, setTextOptionStatus] = useState("เลือกวิธีชำระเงิน");

  // filter
  const handleSetStatus = (e) => {
    payment(e);
  };

  const onChange = (value, dateString) => {
    if (dateString[0] !== "" && dateString[1] !== "") {
      let newdateStatrt = dateString[0]
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("-");
      newdateStatrt += ` ${dateString[0].split(" ")[1]}`;

      let newdateEnd = dateString[1]
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("-");
      newdateEnd += ` ${dateString[1].split(" ")[1]}`;

      timeStart(newdateStatrt);
      timeEnd(newdateEnd);
    } else {
      timeStart("");
      timeEnd("");
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px" }}>
      {/* date picker */}
      <div
        className="datepicker d-flex al-center jc-end"
        style={{ position: "relative" }}
      >
        <div
          className="d-flex al-center jc-between option ml-0"
          style={{ padding: ".5rem" }}
        >
          <AiOutlineCalendar
            style={{ marginRight: "5px", fontSize: "1.5rem" }}
          />
          <Space direction="vertical" size={12}>
            <RangePicker
              locale={locale}
              showTime={{
                format: "HH:mm",
              }}
              format="DD-MM-YYYY HH:mm:ss"
              onChange={onChange}
              //   onOk={onOk}
              bordered={false}
              suffixIcon={null}
              style={{ padding: "0" }}
            />
          </Space>
        </div>
      </div>

      {/* status */}
      <div
        className="d-flex al-center jc-end md-hide"
        style={{ position: "relative", minWidth: "140px" }}
      >
        <div
          style={{ width: "100%" }}
          className="d-flex al-center jc-between option ml-0"
          onClick={() => {
            setPopupStatus(true);
          }}
        >
          {textOptionStatus} <RiArrowDropDownLine />
        </div>
        <div
          className="dropdown"
          style={{ display: popupStatus ? "block" : "none" }}
          onClick={(e) => {
            setPopupStatus(false);
          }}
        >
          <ul
            onClick={(e) => {
              setTextOptionStatus(e.target.innerText);
            }}
          >
            <li
              onClick={() => {
                handleSetStatus("");
              }}
              className={
                textOptionStatus === "เลือกวิธีชำระเงิน"
                  ? "link active"
                  : "link"
              }
            >
              เลือกวิธีชำระเงิน
            </li>
            <li
              onClick={() => {
                handleSetStatus("EMS");
              }}
              className={textOptionStatus === "EMS" ? "link active" : "link"}
            >
              EMS
            </li>
            <li
              onClick={() => {
                handleSetStatus("เก็บเงินปลายทาง");
              }}
              className={
                textOptionStatus === "เก็บเงินปลายทาง" ? "link active" : "link"
              }
            >
              เก็บเงินปลายทาง
            </li>
            <li
              onClick={() => {
                handleSetStatus("ส่งด่วน");
              }}
              className={
                textOptionStatus === "ส่งด่วน" ? "link active" : "link"
              }
            >
              ส่งด่วน
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default FilterChart;
