import axios from "axios";
import { useEffect, useState } from "react";
import { BsThreeDots } from "react-icons/bs";
import { Link } from "react-router-dom";
import "dayjs/locale/th";
import Moment from "react-moment";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const handleCopy = (text) => {
  navigator.clipboard.writeText(text);
};

const handelDel = async (id) => {
  try {
    await axios.delete("/order/" + id);
    window.location.reload();
  } catch (err) {
    alert("เกิดข้อผิดพลาด!");
    console.log(err);
  }
};

const LastOrder = () => {
  const handleClickActionTable = (e) => {
    const parent = e.target.closest(".actions");
    const dropdown = parent.querySelector(".dropdown");

    dropdown.style.display === "none" || dropdown.style.display === ""
      ? (dropdown.style.display = "block")
      : (dropdown.style.display = "none");
  };
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let url = "/order/all/order";
      url += "?status=";
      url += "&page=" + 1;
      url += "&dayStart=0000-00-00";
      url += "&dayEnd=3000-12-30";

      const res = await axios.get(url);
      setData(res.data[1]?.data);
    };
    fetchData();
  }, []);

  return (
    <div className="table">
      <p className="title">รายการสั่งซื้อล่าสุด</p>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>วันที่</th>
            <th>ชื่อลูกค้า</th>
            <th>สถานะ</th>
            <th>ยอดรวม</th>
            <th>คำสั่ง</th>
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0
            ? data.map((d) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  {<Moment format="DD-MM-YYYY">{d?.createdAt}</Moment>}
                </td>
                <td>
                  <div className="d-flex al-center jc-start gap-small">
                    <img
                      className="border-round"
                      src={d?.pictureUrl}
                      alt=""
                    />
                    <span className="name">{d?.displayName}</span>
                  </div>
                </td>
                <td>
                  {d?.status === 1 ? (
                    <span className="status-pending">กำลังดำเนินการ</span>
                  ) : d?.status === 2 ? (
                    <span className="status-complete">เสร็จสิ้น</span>
                  ) : (
                    <span className="status-cancel">ยกเลิก</span>
                  )}
                </td>
                <td>
                <span className='price'>฿{formatNumber(d?.total + d?.free)}</span>
                  {/* {d.status != 2 ? <span className='price'>฿{formatNumber(d?.total)}</span> : <span className='price'>฿{formatNumber(d?.total + d?.free)}</span>} */}
                </td>
                <td>
                  <div className="actions">
                    <BsThreeDots
                      onClick={(e) => {
                        handleClickActionTable(e);
                      }}
                    />
                    <div className="dropdown">
                      <ul
                        onClick={(e) => {
                          handleClickActionTable(e);
                        }}
                      >
                        <li>
                          <Link
                            to={"/admin/order/" + d?.id}
                            className="link"
                          >
                            รายละเอียด
                          </Link>
                        </li>
                        <li
                          className="link"
                          onClick={() => {
                            handleCopy(
                              "https://buriplus.com/complete-order/" +
                              d?.id
                            );
                          }}
                        >
                          คัดลอกลิ้งยืนยันออเดอร์
                        </li>
                        <li>
                          <Link
                            to={"/admin/update-order/" + d?.id}
                            className="link"
                          >
                            แก้ไข
                          </Link>
                        </li>
                        <li
                          className="link red-color"
                          onClick={() => {
                            handelDel(d?.id);
                          }}
                        >
                          ลบ
                        </li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
            ))
            : <tr></tr>}
        </tbody>
      </table>
    </div>
  );
};

export default LastOrder;
