import './mobileNav.css'
// import { IoGridOutline, IoHomeOutline, IoMenuOutline } from 'react-icons/io5'
// import { AiOutlineShopping } from 'react-icons/ai'
import SidebarCart from '../sidebarCart/SidebarCart'
import { useState } from 'react'
import SidebarMenuMobile from '../sidebarMenuMobile/SidebarMenuMobile'
// import { Link } from 'react-router-dom'
// import SidebarCategoryMobile from '../sidebarCategoryMobile/SidebarCategoryMobile'
import { NavLink } from "react-router-dom"
import { AiOutlineHome, AiOutlineShoppingCart, AiOutlineLike } from 'react-icons/ai'


const MobileNav = () => {
    const [showOverlay, setShowOverlay] = useState(false);
    const [showCart, setShowCart] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    // const [showCategory, setShowCategory] = useState(false);

    const hideAll = () => {
        setShowOverlay(false);
        setShowCart(false);
        setShowMenu(false);
        // setShowCategory(false);
    }

    return (
        <>
            {/* <div className='mobileNav d-flex al-center jc-around'> */}
            <div className='mobileNav d-flex al-center jc-start'>
                {/* <button className='d-flex al-center jc-center' onClick={() => { setShowMenu(true); setShowOverlay(true) }}><IoMenuOutline /></button> */}
                <nav className='user main d-flex al-center jc-center w-100'>
                    <ul>
                        <li><NavLink className="list" to="/" style={{margin: '0', display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center' }} ><AiOutlineHome style={{ color: '#fff' }} /> หน้าแรก</NavLink></li>
                        <li onClick={() => { setShowMenu(true); setShowOverlay(true) }} style={{margin: '0', display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center', fontWeight: '600', borderLeft: '1px solid #EDEDED', borderRight: '1px solid #EDEDED' }}><AiOutlineShoppingCart style={{ color: '#fff' }} /> สินค้า</li>
                        {/* <li><NavLink className="list" to="/how-to-order" style={{color: '#fff', margin: '0', display:'flex', justifyContent: 'center', gap: '10px', alignItems: 'center'}}>วิธีการสั่งซื้อ</NavLink></li> */}
                        <li><NavLink className="list" to="/review" style={{margin: '0', display: 'flex', justifyContent: 'center', gap: '5px', alignItems: 'center' }}><AiOutlineLike style={{ color: '#fff' }} /> รีวิวจากลูกค้า</NavLink></li>
                        {/* <li><NavLink className="list" to="/payment">การชำะเงิน</NavLink></li>
                        <li><NavLink className="list" to="/contact-us">ติดต่อเรา</NavLink></li> */}
                    </ul>
                </nav>
                {/* <button className='d-flex al-center jc-center'><Link to="/"><IoHomeOutline /></Link></button> */}
                {/* <button className='d-flex al-center jc-center' onClick={() => { setShowCart(true); setShowOverlay(true) }}><AiOutlineShopping /><span className='d-flex al-center jc-center border-round count'>50</span></button> */}
                {/* <button className='d-flex al-center jc-center'  onClick={() => { setShowCategory(true); setShowOverlay(true) }}><IoGridOutline /></button> */}
            </div>
            <SidebarCart show={showCart} />
            <div onClick={() => { hideAll() }} style={{ position: 'absolute' }}>
                {showMenu && <SidebarMenuMobile show={showMenu} />}
            </div>
            {/* <SidebarCategoryMobile show={showCategory} /> */}
            {showOverlay ? (<div className='u-overlay show' onClick={() => { hideAll() }}></div>) : ''}
        </>
    )
}

export default MobileNav