import './banner.css'
import { useEffect, useRef, useState } from 'react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'

import { Carousel } from 'antd';
import axios from 'axios';


const Banner = () => {
  const slider = useRef(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('/banner')
        setData(res?.data);
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [])

  return (
    <>
      <div className='s-m banner'>
        <Carousel ref={slider} className='container'>
          {data.length > 0 ? (
            data.map(d => (
              <div className="content" key={d?.id}>
                <div className="img">
                  <img src={process.env.REACT_APP_UPLOAD_URL + `/${d?.url}`} alt="" />
                </div>
              </div>
            ))
          ) : (
            <div className="content" style={{textAlign: 'center'}}>
              <div style={{textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '300px'}}>
              Loading...
                </div> 
              {/* <div className="img">
                <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/banner.png'} alt="" />
              </div> */}
            </div>
          )}

        </Carousel>
      </div>
      <div className="s-m d-flex al-center jc-end gap-small banner-controller">
        <button className='d-flex al-center jc-center prev-btn' onClick={() => { slider.current.prev() }}><FaAngleLeft /></button>
        <button className='d-flex al-center jc-center next-btn' onClick={() => { slider.current.next() }}><FaAngleRight /></button>
      </div>
    </>
  )
}

export default Banner