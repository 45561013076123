import "./cart.css";
import StepCheckOut from "../../components/stepCheckOut/StepCheckOut";
import ListTableCart from "../../components/listTableCart/ListTableCart";
import { useNavigate } from "react-router-dom";
import { Radio, Space } from "antd";
import { useEffect, useState } from "react";
import { changeValue } from "../../redux/paymentReducer";

// redux
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const Cart = () => {
  const [changeRadio, setChangeRadio] = useState(false);
  const [loading, setLoading] = useState(false);
  const customer = useSelector((state) => state.customer);

  const navigate = useNavigate();
  const [paymentValue, setPaymentValue] = useState(0);
  // const paymentValue = useSelector((state) => state?.payment?.value);
  const paymentName = useSelector((state) => state?.payment?.name);

  const products = useSelector((state) => state.cart.products);
  const dispatch = useDispatch();

  const [totalItems, setTotalItems] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let items = 0;
    let total = 0;
    products.forEach((p) => {
      total += p.price * p.quantity;
      items += p.quantity;
    });
    setTotal(total);
    setTotalItems(items);
  }, [products]);

  useEffect(() => {
    if (paymentName === "เก็บเงินปลายทาง") {
      handleCod();
    } else if (paymentName === "EMS") {
      handleEms();
    } else if (paymentName === "ส่งด่วน") {
      handleEmsBangkok();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentName, totalItems]);

  const handleCod = () => {
    if (totalItems <= 3) {
      setPaymentValue(50);
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 50,
        })
      );
    } else if (totalItems <= 6) {
      setPaymentValue(70);
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 70,
        })
      );
    } else if (totalItems <= 10) {
      setPaymentValue(90);
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 90,
        })
      );
    } else {
      setPaymentValue(10 * totalItems);
      dispatch(
        changeValue({
          name: "เก็บเงินปลายทาง",
          value: 10 * totalItems,
        })
      );
    }
  };

  const handleEms = () => {
    if (totalItems <= 2) {
      setPaymentValue(50);
      dispatch(
        changeValue({
          name: "EMS",
          value: 50,
        })
      );
    } else {
      setPaymentValue(0);
      dispatch(
        changeValue({
          name: "EMS",
          value: 0,
        })
      );
    }
  };

  const handleEmsBangkok = () => {
    setPaymentValue(0);
    dispatch(
      changeValue({
        name: "ส่งด่วน",
        value: 0,
      })
    );
  };

  const handleOrder = async () => {
    let items = [];
    let quantity = [];
    products.forEach((p) => {
      items.push(p.id);
      quantity.push(p.quantity);
    });

    try {
      // new user
      setLoading(true);
      await axios.post("/customer/", {
        userId: customer?.userId,
        pictureUrl: customer?.pictureUrl,
        displayName: customer?.displayName,
        name: "-",
      });

      const resOrder = await axios.post("/order", {
        userId: customer.userId,
        total: total,
        free: paymentValue,
        status: 1,
        productId: items,
        quantity: quantity,
        payment: paymentName,
      });
      setLoading(false);
      navigate("/complete-order/" + resOrder.data?.insertId);
    } catch (err) {
      alert("เกิดข้อผิดพลาด กรุณาลองใหม่ภายหลัง");
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div className="cart">
      <p className="u-title">ตะกร้าสินค้า</p>
      <StepCheckOut step={1} />

      <div className="d-flex al-start md-f-column mt-2 gap-25px">
        <div className="u-table">
          <table>
            <thead>
              <tr>
                <th>สินค้า</th>
                <th>จำนวน</th>
                <th>มูลค่า</th>
              </tr>
            </thead>
            <tbody>
              {products && products.length > 0 ? (
                products.map((p) => <ListTableCart data={p} key={p.id} />)
              ) : (
                <tr style={{ color: "#fff" }}>
                  <br />
                  ยังไม่มีสินค้าในตะกร้า
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="right">
          <p className="top-title">
            <b>ยอดรวม</b>
          </p>
          <div
            className="d-flex al-start jc-between"
            style={{ borderBottom: "none" }}
          >
            <span>จำนวนสินค้า</span>
            <span>
              <b>{totalItems} ชิ้น</b>
            </span>
          </div>
          <p className="top-title" style={{ marginTop: ".5rem" }}>
            <b>
              วิธีชำระเงิน <span style={{ color: "red" }}>*</span>
            </b>
          </p>
          <div className="d-flex al-start jc-between">
            <Radio.Group>
              <Space direction="vertical">
                <Radio
                  onChange={() => {
                    dispatch(
                      changeValue({
                        name: "เก็บเงินปลายทาง",
                      })
                    );
                    setChangeRadio(true);
                  }}
                  value={1}
                  style={{ fontSize: "1.1rem" }}
                >
                  เก็บเงินปลายทาง/COD
                </Radio>
                <Radio
                  onChange={() => {
                    dispatch(
                      changeValue({
                        name: "EMS",
                      })
                    );
                    setChangeRadio(true);
                  }}
                  value={2}
                  style={{ fontSize: "1.1rem" }}
                >
                  โอนเงิน/EMS
                </Radio>
                <Radio
                  onChange={() => {
                    dispatch(
                      changeValue({
                        name: "ส่งด่วน",
                      })
                    );
                    setChangeRadio(true);
                  }}
                  value={3}
                  style={{ fontSize: "1.1rem" }}
                >
                  ส่งด่วนเขต กทม. 24 ชม.
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <div className="d-flex al-start jc-between">
            <span>ค่าจัดส่ง</span>
            <span>
              {changeRadio ? (
                <b>{paymentName === 0 ? "ฟรี" : `฿${paymentValue}`}</b>
              ) : (
                <b>-</b>
              )}
              {/* <b>{paymentName === 0 ? "ฟรี" : `฿${paymentValue}`}</b> */}
            </span>
          </div>
          <div className="d-flex al-start jc-between">
            <span>
              <b>ยอดรวม</b>
            </span>
            <span>
              <b>฿{formatNumber(total + paymentValue)}</b>
            </span>
          </div>
          <button
            disabled={loading ? true : false}
            onClick={() => {
              if (changeRadio === true) {
                handleOrder();
              } else {
                alert("กรุณาเลือกวิธีการชำระเงิน");
              }
            }}
            style={{ marginTop: "1rem", display: "block" }}
          >
            ดำเนินการสั่งซื้อ
          </button>
        </div>
      </div>
    </div>
  );
};

export default Cart;
