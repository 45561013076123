import "./home.css";
import { useEffect, useState } from "react";
import Banner from "../../components/banner/Banner";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import MobileNav from "../../components/mobileNav/MobileNav";
import Nav from "../../components/nav/Nav";
import Sidebar from "../../components/sidebar/Sidebar";
import DealOfDay from "../../components/dealOfDay/DealOfDay";
import FlashDeal from "../../components/flashDeal/FlashDeal";
import MiniCart from "../../components/miniCart/MiniCart";
import axios from "axios";
import Liff from "../../components/liff/Liff";
import CardProduct from "../../components/cardProduct/CardProduct";

const Home = () => {
  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, []);

  // products
  const [products, setProducts] = useState([]);
  const [productsDeal, setProductsDeal] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
      
        const resDeal = await axios.get("/deal/public/all");
        setProductsDeal(resDeal.data);

        // const res = await axios.get("/product/public?sortBy=id DESC&page=1");
        // setProducts(res.data[1].data);


        let url = "/product/public?";
        url += `sortBy=id ASC&`;
        url += `&page=1&`;
  
        const res = await axios.get(url);
        let resData = res.data[1].data;
        
        let productList = [];
        const soldOut = resData.filter(d=>(d?.soldout === 1))
        const instock = resData.filter(d=>(d?.soldout !== 1))
        productList.push(...instock)
        productList.push(...soldOut)
  
        setProducts(productList);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Header />
      <Nav />
      <Banner />
      <main className="user">
        <Liff home={true} />
        {screenSize > 767 ? <Sidebar /> : <MobileNav />}
        <div className="home">
          {productsDeal && productsDeal.length > 0 ? (
            <>
              <p className="u-title">Deal Of The Day</p>
              <DealOfDay data={productsDeal[0]} />
            </>
          ) : (
            ""
          )}

          <FlashDeal />

          <p className="mt-2 u-title">สินค้าใหม่ทั้งหมด</p>
          <div className="d-grid g-4 md-g-2 gap-1rem">
            {products && products.length > 0
              ? products.map(
                  (data) =>
                  <CardProduct data={data} key={data.id} />
                )
              : ""}
          </div>
        </div>
      </main>
      <Footer />
      <MiniCart />
      {/* {screenSize > 767 ? <MiniCart /> : ''} */}
    </>
  );
};

export default Home;
