import './orderAdmin.css'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { AiOutlineCalendar } from 'react-icons/ai'
import { BsThreeDots } from 'react-icons/bs'
import { useEffect, useState } from 'react'
import { DatePicker, Space } from 'antd';
import 'dayjs/locale/th'
import locale from 'antd/es/date-picker/locale/th_TH';
import { Link } from 'react-router-dom'
import Pagination from '../../components/pagination/Pagination'
import axios from 'axios'
import Moment from 'react-moment'
import AuthService from '../../services/auth.service'

const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};

const OrderAdmin = () => {

  const currentUser = AuthService.getCurrentUser();


  const [popupStatus, setPopupStatus] = useState(false)
  const [textOptionStatus, setTextOptionStatus] = useState('เลือกสถานะ')

  const dateFormatList = ['DD-MM-YYYY', 'DD-MM-YY'];

  const handlePickerDate = (date, dateString) => {
    let month = Number(date?.$M) + 1 < 10 ? '0' + (Number(date?.$M) + 1) : Number(date?.$M) + 1
    dateString !== '' ? setDateStart(date?.$y + "-" + month + "-" + date?.$D) : setDateStart('')
  }
  const handlePickerDateEnd = (date, dateString) => {
    let month = Number(date?.$M) + 1 < 10 ? '0' + (Number(date?.$M) + 1) : Number(date?.$M) + 1
    dateString !== '' ? setDateEnd(date?.$y + "-" + month + "-" + date?.$D) : setDateEnd('')
  }

  const handleClickActionTable = (e) => {
    const parent = e.target.closest('.actions')
    const dropdown = parent.querySelector('.dropdown');

    dropdown.style.display === 'none' || dropdown.style.display === '' ? dropdown.style.display = 'block' : dropdown.style.display = 'none'
  }

  const [data, setData] = useState([])
  // filter
  const [status, setStatus] = useState('')
  const [dateStart, setDateStart] = useState('0000-00-00')
  const [dateEnd, setDateEnd] = useState('3000-12-30')

  // Pagination
  const [countPagination, setCountPagination] = useState(0);
  const [activeBtn, setActiveBtn] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [maxList, setMaxList] = useState(25);

  useEffect(() => {
    const fetchData = async () => {
      let url = '/order/all/order'
      currentUser?.role !== 3 ? url += '?status=' + status : url += '?status=2'

      url += '&page=' + activeBtn
      url += '&dayStart=' + dateStart
      url += '&dayEnd=' + dateEnd

      try {
        const res = await axios.get(url)
        setData(res.data[1]?.data)
        setCountPagination(res.data[0]?.pagination);
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeBtn, dateEnd, dateStart, status])


  const handelDel = async (id) => {
    try {
      await axios.delete('/order/' + id)
      window.location.reload();
    } catch (err) {
      alert('เกิดข้อผิดพลาด!')
      console.log(err)
    }
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  }


  return (
    <div className='order-admin'>
      <div className="d-flex jc-start al-center">
        <h2 className="heading">คำสั่งซื้อ</h2>
      </div>
      {/* Dropdown */}
      <div className="d-flex jc-between al-center">
        <div className='d-flex al-center jc-start gap-1rem'>

          {/* date picker */}
          <div className="datepicker d-flex al-center jc-end" style={{ position: 'relative' }}>
            <div className='d-flex al-center jc-between option ml-0' style={{ padding: '.5rem' }}>
              <AiOutlineCalendar style={{ marginRight: '5px', fontSize: '1.5rem' }} />
              <Space direction="vertical">
                <DatePicker locale={locale} style={{ padding: '0' }} format={dateFormatList} onChange={handlePickerDate} bordered={false} suffixIcon={null} placeholder="เลือกวันที่เริ่มต้น" />
              </Space>
              <Space direction="vertical">
                <DatePicker locale={locale} style={{ padding: '0' }} format={dateFormatList} onChange={handlePickerDateEnd} bordered={false} suffixIcon={null} placeholder="เลือกวันที่สิ้นสุด" />
              </Space>
            </div>
          </div>

          {/* status */}
          {currentUser?.role !== 3 ? (
            <div className="d-flex al-center jc-end md-hide" style={{ position: 'relative' }}>
              <div className='d-flex al-center jc-between option ml-0' onClick={() => { setPopupStatus(true) }}>{textOptionStatus}  <RiArrowDropDownLine /></div>
              <div className="dropdown" style={{ display: popupStatus ? 'block' : 'none' }} onClick={(e) => { setPopupStatus(false); }}>
                <ul onClick={(e) => { setTextOptionStatus(e.target.innerText) }}>
                  <li onClick={() => {
                    setStatus('')
                  }} className={textOptionStatus === 'เลือกหมวดหมู่' ? 'link active' : 'link'}>เลือกสถานะ</li>
                  <li onClick={() => {
                    setStatus(2)
                  }} className={textOptionStatus === 'เสร็จสิ้น' ? 'link active' : 'link'}>เสร็จสิ้น</li>
                  <li onClick={() => {
                    setStatus(1)
                  }} className={textOptionStatus === 'กำลังดำเนินการ' ? 'link active' : 'link'}>กำลังดำเนินการ</li>
                  <li onClick={() => {
                    setStatus(0)
                  }} className={textOptionStatus === 'ยกเลิก' ? 'link active' : 'link'}>ยกเลิก</li>
                </ul>
              </div>
            </div>
          ) : ''}

        </div>
        {/* <input type="number" className='list-per-page' onChange={(e) => { setMaxList(e.target.value) }} placeholder='จำนวนรายการ/หน้า' /> */}
      </div>
      {/* table */}
      <div className="table">
        <p className="title">คำสั่งซื้อทั้งหมด</p>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>วันที่</th>
              <th>ชื่อลูกค้า</th>
              <th>วิธีการชำระเงิน</th>
              <th>สถานะ</th>
              <th>ยอดรวม</th>
              <th>คำสั่ง</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ?
              (
                data.map(data => (
                  <tr key={data.id}>
                    <td>{data.id}</td>
                    <td>{<Moment format="DD-MM-YYYY">{data?.createdAt}</Moment>}</td>
                    <td>
                      <div className="d-flex al-center jc-start gap-small">
                        {currentUser?.role !== 3 ? (
                          <img className='border-round' src={data?.pictureUrl} alt="" />
                        ) : (
                          <img className='border-round' src={process.env.REACT_APP_UPLOAD_URL + '/avatar.jpg'} alt="" />
                        )}

                        <span className='name'>{data?.displayName}</span>
                      </div>
                    </td>
                    <td>
                    
                      {data?.payment === 'เก็บเงินปลายทาง' ? (
                        <span style={{ fontSize: '12px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#5dbbd5', color: '#fff' }}>{data?.payment}</span>
                      ) :data?.payment === 'ส่งด่วน' ? (
                        <span style={{ fontSize: '12px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#61c200', color: '#fff' }}>{data?.payment}</span>
                      ) : (
                        <span style={{ fontSize: '12px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#d2d6d7', color: '#4a3e4a' }}>{data?.payment}</span>
                      )}
                    </td>
                    <td>
                      {data?.status === 1 ? (
                        <span className="status-pending">กำลังดำเนินการ</span>
                      ) : data?.status === 2 ? (
                        <span className='status-complete'>เสร็จสิ้น</span>
                      ) : (
                        <span className="status-cancel">ยกเลิก</span>
                      )}
                    </td>
                    <td>
                      <span className='price'>฿{formatNumber(data?.total + data?.free)}</span>
                      {/* {data.status != 2 ? <span className='price'>฿{formatNumber(data?.total)}</span> : <span className='price'>฿{formatNumber(data?.total + data?.free)}</span>} */}
                    </td>
                    <td>
                      <div className='actions'>

                        <BsThreeDots onClick={(e) => { handleClickActionTable(e) }} />
                        <div className="dropdown">
                          <ul onClick={(e) => { handleClickActionTable(e) }} >
                            <li><Link to={"/admin/order/" + data?.id} className='link'>รายละเอียด</Link></li>
                            {currentUser?.role !== 3 ? (
                              <>
                                <li className='link' onClick={() => {
                                  handleCopy(process.env.REACT_APP_SITE_URL+ '/complete-order/' + data?.id)
                                }}>คัดลอกลิ้งยืนยันออเดอร์</li>
                                <li><Link to={'/admin/update-order/' + data?.id} className='link'>แก้ไข</Link></li>
                                <li className='link red-color' onClick={() => {
                                  handelDel(data?.id)
                                }}>ลบ</li>
                              </>
                            ) : ''}
                          </ul>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
              ) : <tr></tr>}
          </tbody>
        </table>
      </div>
      <Pagination
        total={countPagination}
        active={activeBtn}
        action={setActiveBtn}
        limit={maxList}
      />
    </div>
  )
}

export default OrderAdmin