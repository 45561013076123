import { useEffect, useState } from 'react';
import { RiImageAddFill } from 'react-icons/ri'
import { AiOutlinePlus } from 'react-icons/ai'
import { BsThreeDots } from 'react-icons/bs'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AuthService from '../../services/auth.service';


const AddReview = () => {

    const currentUser = AuthService.getCurrentUser();
    const navigate = useNavigate();

    // check role
    useEffect(() => {
        if (currentUser?.role === 3) {
            navigate("/admin/orders");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser?.role])


    const [newReview, setNewReview] = useState(true);
    const [updateReviewId, setUpdateReviewId] = useState(null);
    const [previewOldImg, setPreviewOldImg] = useState('');
    const [changeNewImg, setChangeNewImg] = useState(false);

    // thumbnail
    const [thumbnail, setThumbnail] = useState();
    const thumbnailChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setThumbnail(e.target.files[0]);
        }
    };


    // const [values, setValues] = useState({
    //     name: '',
    //     job: '',
    //     detail: '',
    // })

    // const handleChangeValue = (e) => {
    //     setValues(prev => ({
    //         ...prev,
    //         [e.target.name]: e.target.value
    //     }))
    // }

    const handleAddReview = async () => {
        if (thumbnail === undefined) {
            alert('กรุณากรอกข้อมูลให้ครบถ้วน!');
            return;
        } else {
            try {
                let formData = new FormData();
                formData.append("img", thumbnail);
                // formData.append("name", values.name);
                // formData.append("job", values.job);
                // formData.append("detail", values.detail);

                const res = await axios
                    .post("/review", formData, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                if (res.status === 200) {
                    window.location.reload();
                }
            } catch (err) {
                alert('เกิดข้อผิดพลาด!')
                console.log(err)
            }
        }
    }
    const handleUpdate = async () => {
        try {
            let formData = new FormData();
            formData.append("img", thumbnail);
            // formData.append("name", values.name);
            // formData.append("job", values.job);
            // formData.append("detail", values.detail);

            const res = await axios
                .put("/review/" + updateReviewId, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
            if (res.status === 200) {
                alert('แก้ไขสำเร็จ')
                window.location.reload();
            }
        } catch (err) {
            alert('เกิดข้อผิดพลาด!')
            console.log(err)
        }
    }

    const [data, setData] = useState([])
    useEffect(() => {
        const fetchReviewList = async () => {
            try {
                const res = await axios.get('/review');
                setData(res?.data)
            } catch (err) {
                console.log(err)
            }
        }

        fetchReviewList()
    }, [])

    // const onChangeToggle = async (checked, id) => {
    //     try {
    //         let status;
    //         checked ? (status = 1) : (status = 0);
    //         await axios.put("/review/r/" + id, {
    //             full: status,
    //         });
    //     } catch (err) {
    //         alert('เกิดข้อผิดพลาด!')
    //         console.log(err)
    //     }

    // };
    const handleDel = async (id) => {
        try {
            const res = await axios.delete("/review/" + id);
            if (res.status === 200) {
                let prevData = data
                const newData = prevData.filter(p => (p.id !== id))
                setData(newData)
            }
        } catch (err) {
            alert('เกิดข้อผิดพลาด!')
            console.log(err)
        }
    };


    const handleClickActionTable = (e) => {
        const parent = e.target.closest(".actions");
        const dropdown = parent.querySelector(".dropdown");

        dropdown.style.display === "none" || dropdown.style.display === ""
            ? (dropdown.style.display = "block")
            : (dropdown.style.display = "none");
    };

    return (
        <div className="categories">
            <div className="d-flex jc-between al-center margin-bt-1">
                <h2 className="heading">รีวิวจากลูกค้า</h2>
                <button
                    className="primary-button"
                    onClick={() => {
                        setNewReview(true);
                        setThumbnail(undefined)
                    }}
                >
                    <AiOutlinePlus /> เพิ่มรีวิว
                </button>
            </div>

            <div className="d-flex gap-25px md-f-rev-column">
                {/* table */}
                <div className="table mt-0">
                    <p className="title">รีวิวจากลูกค้า</p>
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>รูปภาพ</th>
                                {/* <th>ชื่อลูกค้า</th>
                                <th>ตำแหน่งงาน</th>
                                <th>ข้อความรีวิว</th>
                                <th>รีวิวสไลด์</th> */}
                                <th>คำสั่ง</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? (
                                data.map(d => (
                                    <tr key={d.id}>
                                        <td>{d.id}</td>
                                        <td>
                                            <img style={{ objectFit: 'cover', width: '150px', height: 'auto' }} src={
                                                process.env.REACT_APP_UPLOAD_URL + `/${d?.img}`
                                            } alt="" />
                                        </td>
                                        {/* <td>{d?.name}</td>
                                        <td style={{ maxWidth: '350px' }}>{d?.job}</td>
                                        <td>{d?.detail}</td>
                                        <td>
                                            <Switch
                                                defaultChecked={d?.full === 1 ? true : false}
                                                onChange={(e) => {
                                                    onChangeToggle(e, d.id);
                                                }}
                                            />
                                        </td> */}
                                        <td>
                                            <div className="actions">
                                                <BsThreeDots
                                                    onClick={(e) => {
                                                        handleClickActionTable(e);
                                                    }}
                                                />
                                                <div className="dropdown">
                                                    <ul
                                                        onClick={(e) => {
                                                            handleClickActionTable(e);
                                                        }}
                                                    >
                                                        <li className='link' onClick={() => {
                                                            setUpdateReviewId(d.id);
                                                            setNewReview(false);
                                                            // setValues({
                                                            //     name: d?.name,
                                                            //     job: d?.job,
                                                            //     detail: d?.detail,
                                                            // });
                                                            setPreviewOldImg(d?.img);
                                                            setChangeNewImg(false);
                                                            setThumbnail(undefined)
                                                        }}>
                                                            แก้ไข
                                                        </li>
                                                        <li
                                                            className="link red-color"
                                                            onClick={() => {

                                                                if (window.confirm("คุณต้องการลบรีวิวนี้หรือไม่?")) {
                                                                    handleDel(d?.id)
                                                                };
                                                            }}
                                                        >
                                                            ลบ
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : <tr></tr>}

                        </tbody>
                    </table>
                </div>

                {/* form */}
                <div className="box-content cate-form" style={{ maxWidth: '400px', width: '400px', minWidth: '400px' }}>
                    <p className="title">{newReview ? 'เพิ่มรีวิว' : 'แก้ไขรีวิว'}</p>

                    {/* image */}
                    <div style={{ padding: '1.5rem', paddingBottom: '0' }}>
                        <p className="title" style={{ borderBottom: 'none' }}>
                            ภาพโปรไฟล์<span style={{ color: 'var(--a-red-color)' }}>*</span>
                        </p>
                        <label
                            htmlFor="thumbnail"
                            className="d-flex al-center jc-center thumbnail"
                            style={{
                                width: '100%',
                                minHeight: '260px',
                                backgroundColor: 'var(--a-bg-color)',
                                borderRadius: '10px',
                                marginBottom: '25px',
                                overflow: 'hidden',
                                cursor: 'pointer'
                            }}
                        >
                            <input
                                accept="image/*"
                                type="file"
                                onChange={(e => {
                                    thumbnailChange(e);
                                    setChangeNewImg(true)
                                })}
                                id="thumbnail"
                                style={{ display: "none" }}
                            />
                            {!newReview && !changeNewImg ? (
                                <img
                                    src={process.env.REACT_APP_UPLOAD_URL + `/${previewOldImg}`}
                                    alt="Thumb"
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover",
                                    }}
                                />
                            ) : (
                                thumbnail === undefined ? (
                                    <div className="d-flex f-column al-center jc-center">
                                        <RiImageAddFill style={{
                                            fontSize: '5rem',
                                            color: 'var(--a-p-color)'
                                        }} />
                                        <small>คลิ๊กเพื่อเพิ่มภาพโปรไฟล์</small>
                                    </div>
                                ) : (
                                    <img
                                        src={URL.createObjectURL(thumbnail)}
                                        alt="Thumb"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                )
                            )}
                        </label>
                    </div>


                    {/* <div className="container-input" style={{ paddingTop: '0' }}>
                        <p className="title" style={{ borderBottom: 'none' }}>
                            ชื่อลูกค้า<span style={{ color: 'var(--a-red-color)' }}>*</span>
                        </p>
                        <input
                            type="text"
                            placeholder="กรอกชื่อลูกค้า"
                            name="name"
                            onChange={handleChangeValue}
                            value={values?.name}
                        />

                        <p className="title" style={{ borderBottom: 'none' }}>
                            ตำแหน่งงาน
                        </p>
                        <input
                            type="text"
                            placeholder="กรอกตำแหน่งงานลูกค้า"
                            name='job'
                            onChange={handleChangeValue}
                            value={values?.job}
                        />

                        <p className="title" style={{ borderBottom: 'none' }}>
                            ข้อความรีวิว<span style={{ color: 'var(--a-red-color)' }}>*</span>
                        </p>
                        <textarea
                            type="text"
                            placeholder="กรอกข้อความรีวิว"
                            name="detail"
                            style={{ width: '100%' }}
                            rows={5}
                            onChange={handleChangeValue}
                            value={values?.detail}
                        />

                    </div> */}

                    <div className="d-flex al-center jc-between gap-small" style={{ padding: '0 1.5rem 1rem 1.5rem', width: '100%', boxSizing: 'border-box' }}>

                        {newReview ? (
                            <button
                                style={{ width: '100%' }}
                                className="primary-button"
                                onClick={handleAddReview}
                            >เพิ่มรีวิว</button>
                        ) : (
                            <button
                                style={{ width: '100%' }}
                                className="primary-button"
                                onClick={handleUpdate}
                            >แก้ไขรีวิว</button>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddReview