import './orderDetailAdmin.css'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineCopy, AiOutlineUser, AiOutlineShoppingCart, AiOutlineFileText } from 'react-icons/ai'
import { useEffect, useState } from 'react';
import axios from 'axios';
import Moment from 'react-moment'
import AuthService from '../../services/auth.service';


const formatNumber = (inputNumber) => {
  let formetedNumber = Number(inputNumber)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  let splitArray = formetedNumber.split(".");
  if (splitArray.length > 1) {
    formetedNumber = splitArray[0];
  }
  return formetedNumber;
};


const OrderDetailAdmin = () => {
  const currentUser = AuthService.getCurrentUser();

  const { id } = useParams();

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  }

  const [data, setData] = useState('')
  const [customer, setCustomer] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const fetchDataCustomer = async (id) => {
    try {

      const res = await axios.get('/customer/u/' + id);
      setCustomer(res.data[0]);
    } catch (err) {
      console.log(err)
    }

  }

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await axios.get('/order/' + id)
        if (res.data[0]?.userId) {
          fetchDataCustomer(res.data[0]?.userId)
        }

        setData(res.data[0])

        let total = 0
        let price = 0
        res.data[0].products.forEach(d => {
          total += d?.quantity
          price += d?.quantity * d?.price
        });

        price += res.data[0]?.free;

        setTotalItems(total)
        setTotalPrice(price)
      } catch (err) {
        console.log(err)
      }
    }
    fetchApi();
  }, [id])


  return (
    <div className='order-detail'>
      <div className="d-flex jc-start al-center margin-bt-1">
        <h2 className="heading">รายละเอียดคำสั่งซื้อ</h2>
      </div>

      <div className='container'>
        <div className="d-flex al-start jc-between header">
          <div>
            <p><b>หมายเลขคำสั่งซื้อ #{id}</b></p>
            <p className='date'>วันที่ {<Moment format="DD-MM-YYYY เวลา HH:mm">{data?.createdAt}</Moment>} น.</p>
            {data?.status === 1 ? (
              <span className="status-pending">กำลังดำเนินการ</span>
            ) : data?.status === 2 ? (
              <span className='status-complete'>เสร็จสิ้น</span>
            ) : (
              <span className="status-cancel">ยกเลิก</span>
            )}
            {data?.payment === 'เก็บเงินปลายทาง' ? (
              <span style={{ fontSize: '12px', marginLeft: '10px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#5dbbd5', color: '#fff' }}>{data?.payment}</span>
            ) : data?.payment === 'ส่งด่วน' ? (
              <span style={{ fontSize: '12px', marginLeft: '10px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#61c200', color: '#4a3e4a' }}>{data?.payment}</span>
            ) : (
              <span style={{ fontSize: '12px', marginLeft: '10px', padding: '3px 10px', borderRadius: '30px', backgroundColor: '#d2d6d7', color: '#4a3e4a' }}>{data?.payment}</span>
            )}

          </div>
          {currentUser?.role !== 3 ? (
            <div className='d-flex al-center jc-center actions'>
              <Link to={"/admin/update-order/" + id}><button className='d-flex al-center jc-center'>แก้ไข</button></Link>
              <button className='d-flex al-center jc-center ml-small' onClick={handleCopy('https://buriplus.com/complete-order/' + data?.id)}><AiOutlineCopy /></button>
            </div>
          ) : ''}
        </div>

        {/* content */}
        <div className="d-grid g-2 gap-25px detail-content md-g-1">
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              {customer?.pictureUrl !== '' && currentUser?.role !== 3 ? (
                <img src={customer?.pictureUrl} style={{ width: '100%', height: '100%', objectFit: 'cover' }} alt="" />
              ) : <AiOutlineUser />}
            </div>
            <div>
              <p className="title"><b>ข้อมูลลูกค้า</b></p>
              {/* <p>ชื่อ - นามสกุล : <b>{customer?.name}</b></p> */}
              <p>Line Name : <b>{customer?.displayName}</b></p>
              <p>uid : <b>{customer?.userId}</b></p>
            </div>
          </div>
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              <AiOutlineShoppingCart />
            </div>
            <div>
              <p className="title"><b>รายละเอียคำสั่งซื้อ</b></p>
              <p>สถานะ : <b>{data?.status === 1 ? (
                'กำลังดำเนินการ'
              ) : data?.status === 2 ? (
                'เสร็จสิ้น'
              ) : (
                'ยกเลิก'
              )}</b></p>
              <p>แก้ไขล่าสุด : {<Moment format="DD-MM-YYYY เวลา HH:mm">{data?.updatedAt}</Moment>} น. </p>
            </div>
          </div>
          <div className="d-flex al-start jc-start md-f-column md-gap-small">
            <div className="icon d-flex al-center jc-center border-round">
              <AiOutlineFileText />
            </div>
            <div>
              <p className="title"><b>Note (สำหรับ Admin)</b></p>
              <div className="long-text">{data?.note}</div>
            </div>
          </div>
        </div>
      </div>

      {/* table */}
      <div className="table">
        <p className="title">รายการสินค้า</p>
        <table>
          <thead>
            <tr>
              <th>ชื่อสินค้า</th>
              <th>ราคาสินค้า</th>
              <th>จำนวนสินค้า</th>
              <th>ยอดรวม</th>
            </tr>
          </thead>
          <tbody>
            {data && data?.products.length > 0 ? (
              data?.products.map(p => (
                <tr key={p.id}>
                  <td>
                    <div className='d-flex al-center jc-start'>
                      <div className="image">
                        <img src={process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`} alt="" />
                      </div>
                      <span>{p?.name}</span>
                    </div>
                  </td>
                  <td>฿{formatNumber(p?.price)}</td>
                  <td>{p?.quantity}</td>
                  <td>฿{formatNumber(p?.price * p?.quantity)}</td>
                </tr>
              ))
            ) : <tr></tr>}
          </tbody>
          <tfoot>
            <tr style={{ borderTop: '1px solid var(--a-border-color)', backgroundColor: 'white' }}>
              <td>ค่าจัดส่ง</td>
              <td></td>
              <td></td>
              <td><b>฿{data && data?.free > 0 ? formatNumber(data?.free) : 0}</b></td>
            </tr>
            <tr>
              <td><b>ยอดรวม</b></td>
              <td></td>
              <td>{totalItems}</td>
              <td><b>฿{formatNumber(totalPrice)}</b></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  )
}

export default OrderDetailAdmin