import './headerAdmin.css'
import { RiArrowDropDownLine } from 'react-icons/ri'
import { VscListSelection } from 'react-icons/vsc'

// import NotificationAdmin from '../notificationAdmin/NotificationAdmin'
import { useEffect, useState } from 'react'
import SidebarAdminMobile from '../sidebarAdminMobile/SidebarAdminMobile'
// import SearchBox from '../searchBox/SearchBox'

import AuthService from "../../services/auth.service";
import { Link } from 'react-router-dom'

const HeaderAdmin = () => {
  const user = AuthService.getCurrentUser();

  const [screenSize, setScreenSize] = useState(0);
  const [showPopup, setShowPopup] = useState(false)

  useEffect(() => {
    setScreenSize(window.innerWidth);
  }, [])

  const logout = () => {
    AuthService.logout().then(
      () => {
        window.location.reload();
      },
      (error) => {
        alert("เกิดข้อผิดพลาด!");
        console.log(error);
      }
    );
  }

  return (

    <>
      {screenSize > 767 ?
        <header className='admin d-flex al-center'>
          <div className="start">
            <div className="logo d-flex al-center">
              <Link to="/admin" className='logo d-flex al-center'>
              <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/logo.png'} alt="" />
              </Link>
            </div>
          </div>
          <div className='end d-flex al-center jc-between'>
            {/* <SearchBox /> */}
            <div></div>
            <div className="d-flex al-center jc-end right">
              {/* <NotificationAdmin /> */}
              <div className="d-flex al-center jc-between userInfo" style={{ borderLeft: 'none' }} onClick={() => { setShowPopup(!showPopup) }}>
                <div className="border-round avatar">
                  <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/avatar.jpg'} alt="" />
                </div>
                <span className='username'>{user?.username}</span>
                <RiArrowDropDownLine />
                <div className="dropdown" style={{ display: showPopup ? 'block' : 'none' }}>
                  <ul>
                    {/* <li className='link primary-color'>จัดการบัญชี</li> */}
                    <li className='link red-color' onClick={logout}>ออกจากระบบ</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        :
        <header className='admin d-flex al-center jc-between'>
          <div className="icon d-flex al-center jc-center border-round bg-primary">
            <div className="d-flex al-center jc-center">
              <VscListSelection onClick={() => { setShowPopup(true) }} />
            </div>
          </div>
          <div className="start">
            <div className="logo d-flex al-center jc-center">
              <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/logo.png'} alt="" />
            </div>
          </div>
          <div className='icon'></div>
          <SidebarAdminMobile click={showPopup} setClick={setShowPopup} />
        </header>}
    </>
  )
}

export default HeaderAdmin