import { useEffect, useState } from "react";
import "./category.css";
import { RiArrowDropDownLine } from "react-icons/ri";
import CardProduct from "../../components/cardProduct/CardProduct";
// import PaginationUser from "../../components/paginationUser/PaginationUser";
import { useParams } from "react-router-dom";
import axios from "axios";
// redux
import { useSelector } from "react-redux";

const Category = () => {
  const { id } = useParams();
  const [sortText, setSortText] = useState("ค่าเริ่มต้น");
  const [showFilter, setShowFilter] = useState(false);

  const handleFilterSort = (el) => {
    setSortText(el.target.innerText);
  };

  // Pagination
  // const [countPagination, setCountPagination] = useState(0);
  const [activeBtn] = useState(1);
  // filter
  const [sort, setSort] = useState("id ASC");

  // products
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let url = "/product/public?";
      url += `cateId=${id}&`;
      url += `sortBy=${sort}&`;
      url += `&page=${activeBtn}&`;

      try {
        const res = await axios.get(url);

        let resData = res.data[1].data;
        // setCountPagination(res.data[0].pagination)
        
        let productList = [];
        const soldOut = resData.filter(d=>(d?.soldout === 1))
        const instock = resData.filter(d=>(d?.soldout !== 1))
        productList.push(...instock)
        productList.push(...soldOut)
  
        setProducts(productList);


      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, sort, activeBtn]);

  const name = useSelector((state) => state.cate.name);
  const [cateName, setCateName] = useState("");

  useEffect(() => {
    setCateName(name);
  }, [name]);

  return (
    <div className="Category">
      <div className="d-flex al-center jc-between">
        <h2>{cateName}</h2>
        <div
          className="sort-by"
          onClick={() => {
            setShowFilter(!showFilter);
          }}
        >
          <div className="d-flex al-center gap-small jc-between">
            <span>{sortText} </span>
            <RiArrowDropDownLine />
          </div>
          <ul className={showFilter ? "drop-down show" : "drop-down"}>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("id ASC");
              }}
            >
              ค่าเริ่มต้น
            </li>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("name ASC");
              }}
            >
              ชื่อ A-Z
            </li>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("name DESC");
              }}
            >
              ชื่อ Z-A
            </li>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("id DESC");
              }}
            >
              ใหม่สุด - เก่าสุด
            </li>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("price ASC");
              }}
            >
              ถูกสุด - แพงสุด
            </li>
            <li
              onClick={(e) => {
                handleFilterSort(e);
                setSort("price DESC");
              }}
            >
              แพงสุด - ถูกสุด
            </li>
          </ul>
        </div>
      </div>

      <div className="d-grid g-4 md-g-2 mt-1 gap-1rem">
        {products?.length > 0
          ? products?.map((p) => <CardProduct data={p} key={p.id} />)
          : ""}
      </div>
      <div className="d-flex al-center jc-center mt-2">
        {/* <PaginationUser
          total={countPagination}
          active={activeBtn}
          action={setActiveBtn}
        /> */}
      </div>
    </div>
  );
};

export default Category;
