import './contact.css'
import { Link } from 'react-router-dom'
import { BsFillTelephoneFill, BsLine, BsFacebook, BsFillGeoAltFill } from 'react-icons/bs'
import { AiFillMail } from 'react-icons/ai'
import FormContact from '../../components/formComtact/FormContact'

const Contact = () => {
    return (
        <div className='contacts'>
            <p className="u-title">ติดต่อเรา</p>
            <div className="d-flex al-center jc-center mt-3">
                <img src={process.env.REACT_APP_PUBLIC_FOLDER + '/assets/logo.png'} className="logo" alt="" />
            </div>

            {/* <div style={{width: '60%', margin: '0 auto'}}> */}
            <div className="d-grid g-2 md-g-1">
                <div className='left'>
                    <div>
                        <p><b>เบอร์โทรติดต่อ</b></p>
                        <Link><span><BsFillTelephoneFill /> 081-234-5678</span></Link>
                    </div>
                    <div>
                        <p><b>อีเมลติดต่อ</b></p>
                        <Link><span><AiFillMail /> demoemail@gmail.com</span></Link>
                    </div>
                    <div>
                        <p><b>ช่องทางติดต่ออื่น ๆ</b></p>
                        <Link><p><BsLine />lineid</p></Link>
                        <Link><p><BsFacebook />facebook</p></Link>
                    </div>
                    <div>
                        <p><b>ที่อยู่</b></p>
                        <div className="d-flex al-start jc-start">
                            <BsFillGeoAltFill /><span className='address'> 123/4 หมู่ 10 ถ.บรมราชชนนี <br />
                                ศาลาธรรมสพน์ กรุงเทพฯ 1017</span>
                        </div>

                    </div>
                </div>
                <FormContact />
            </div>

        </div>
    )
}

export default Contact