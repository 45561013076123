import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { BiSearch } from 'react-icons/bi'
import { RiArrowDropDownLine } from 'react-icons/ri'
import Pagination from '../pagination/Pagination'
// import SearchBox from '../searchBox/SearchBox'
import './addProductOrder.css'

const formatNumber = (inputNumber) => {
    let formetedNumber = Number(inputNumber)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    let splitArray = formetedNumber.split(".");
    if (splitArray.length > 1) {
        formetedNumber = splitArray[0];
    }
    return formetedNumber;
};

const AddProductOrder = ({ show, prevData, setPrevData, defaultValue, setValue }) => {

    const [popupCate, setPopupCate] = useState(false)
    const [textOptionCate, setTextOptionCate] = useState('เลือกหมวดหมู่')

    const itemCount = useRef(null)
    const [insertValue, setInsertValue] = useState(1);

    // filter
    const [filterCate, setFilterCate] = useState("");
    const [filterText, setFilterText] = useState("");

    // category
    const [cate, setCate] = useState([]);
    useEffect(() => {
        const fetchCate = async () => {
            try {
                const res = await axios.get("/category/all");
                setCate(res.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchCate();
    }, []);

    const [textOptionCateFlash] = useState("เลือกหมวดหมู่");


    // Pagination
    const [countPagination, setCountPagination] = useState(0);
    const [activeBtn, setActiveBtn] = useState(1);

    // products
    const [products, setProducts] = useState([]);
    useEffect(() => {
        let url = "/product/admin?status=";
        filterCate !== "" ? (url += `&cateId=${filterCate}`) : (url += `&cateId=`);
        filterCate !== undefined
            ? (url += `&page=${activeBtn}`)
            : (url += `page=${activeBtn}`);

        const fetchProducts = async () => {
            try {
                const res = await axios.get(url);
                setProducts(res.data[1]?.data);
                setCountPagination(res.data[0]?.pagination);
            } catch (err) {
                console.log(err);
            }
        };
        fetchProducts();
    }, [filterCate, activeBtn]);

    const filterByText = async () => {
        let url = "/product/admin?status=";
        filterCate !== "" ? (url += `&cateId=${filterCate}`) : (url += `&cateId=`);
        filterCate !== undefined
            ? (url += `&page=${activeBtn}`)
            : (url += `page=${activeBtn}`);
        filterText !== "" ? (url += `&textName=${filterText}`) : (url += "");

        try {
            const res = await axios.get(url);
            setProducts(res.data[1]?.data);
            setCountPagination(res.data[0]?.pagination);
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddProduct = (id, value) => {
        const newProduct = [...prevData]
        let checkProduct = newProduct.find(p => p.id === id)
        if (!checkProduct) {
            let data = products.filter(p => p.id === id)
            data[0].quantity = value;
            newProduct.push(data[0])
            setPrevData(newProduct)

        } else {
            checkProduct.quantity += value;
            let data = newProduct.filter(p => p.id !== id);
            data.push(checkProduct)
            setPrevData(data);

            // // ลบอันเก่าออก
            // // update axios data
            // let prevData = defaultValue
            // // สินค้าเก่า
            // let arrDelProduct = prevData.deleteProduct.filter(d => (d !== id))
            // arrDelProduct.push(id)
            // prevData.deleteProduct = arrDelProduct
        }
    }

    return (
        <>
            {show ? (
                <div className='f-flex al-center jc-center add-product-order'>
                    <p className="title">เพิ่มสินค้า</p>
                    <div className="d-flex al-center jc-between">
                        <div className="d-flex al-center jc-start input search-box">
                            <BiSearch />
                            <input
                                type="text"
                                placeholder="ค้นหา..."
                                onChange={(e) => {
                                    setFilterText(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.code === "Enter") {
                                        setActiveBtn(1);
                                        filterByText();
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex al-center jc-end" style={{ position: 'relative' }}>
                            <div className='d-flex al-center jc-between option ml-0' onClick={() => { setPopupCate(true) }}>{textOptionCate}  <RiArrowDropDownLine /></div>
                            <div className="dropdown" style={{ display: popupCate ? 'block' : 'none' }} onClick={(e) => { setPopupCate(false); }}>
                                <ul
                                    onClick={(e) => {
                                        setTextOptionCate(e.target.innerText);
                                    }}
                                >
                                    <li
                                        className={
                                            textOptionCateFlash === "เลือกหมวดหมู่"
                                                ? "link active"
                                                : "link"
                                        }
                                        onClick={() => {
                                            setFilterCate("");
                                            setActiveBtn(1);
                                        }}
                                    >
                                        เลือกหมวดหมู่
                                    </li>
                                    {cate.length > 0
                                        ? cate.map((c) => (
                                            <li
                                                key={c.id}
                                                className={
                                                    textOptionCateFlash === `${c.name}`
                                                        ? "link active"
                                                        : "link"
                                                }
                                                onClick={() => {
                                                    setFilterCate(c.id);
                                                    setActiveBtn(1);
                                                }}
                                            >
                                                {c.name}
                                            </li>
                                        ))
                                        : ""}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* table */}
                    <div className="table">
                        <p className="title">รายการสินค้า</p>
                    <div style={{overflowY: 'scroll', maxHeight: '300px'}}>
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>รูปภาพ</th>
                                    <th>ชื่อสินค้า</th>
                                    <th>หมวดหมู่สินค้า</th>
                                    <th>ราคา</th>
                                    <th>จำนวนสินค้า</th>
                                    <th>คำสั่ง</th>
                                </tr>
                            </thead>
                            <tbody>
                                {products && products.length > 0 ? (
                                    products.map(p => (
                                        <tr key={p.id}>
                                            <td>{p.id}</td>
                                            <td>
                                                <div className="d-flex al-center jc-start">
                                                    <img src={process.env.REACT_APP_UPLOAD_URL + `/${p?.thumbnail}`} alt="" />
                                                </div>
                                            </td>
                                            <td>{p?.name}</td>
                                            <td>{p?.category?.name !== undefined
                                                ? p?.category?.name
                                                : "-"}</td>
                                            <td>{formatNumber(p?.price)}฿</td>
                                            <td>
                                                <input type="number" ref={itemCount} onChange={(e) => {
                                                    setInsertValue(e.target.value)
                                                }} defaultValue={1} />
                                            </td>
                                            <td><button className='primary-button' onClick={() => {
                                                handleAddProduct(p?.id, Number(insertValue))
                                            }}>เพิ่มสินค้า</button></td>
                                        </tr>
                                    ))
                                ) : <tr></tr>}
                                {/* <tr>
                                    <td>1</td>
                                    <td>
                                        <div className="d-flex al-center jc-start">
                                            <img src="https://images.pexels.com/photos/14443587/pexels-photo-14443587.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="" />
                                        </div>
                                    </td>
                                    <td>แป้งพับ</td>
                                    <td>เครื่องสำอาง</td>
                                    <td>600฿</td>
                                    <td>
                                        <input type="number" defaultValue={1} />
                                    </td>
                                    <td><button className='primary-button'>เพิ่มสินค้า</button></td>
                                </tr> */}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    <Pagination
                        total={countPagination}
                        active={activeBtn}
                        action={setActiveBtn}
                    />

                </div>
            ) : ''}
        </>
    )
}

export default AddProductOrder